import React from "react";

import Screen from "../components/screen";
import SEO from "../components/seo";
import Logo from "../images/prairie-tribune-logo.svg";

import styles from "./index.module.css";


const IndexPage = () => (
    <Screen>
        <SEO />
        <Logo className={ styles.logo } />
        <a className={ styles.contact } href="mailto:hello@prairietribune.com">hello@prairietribune.com</a>
    </Screen>
);

export default IndexPage;
